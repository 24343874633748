import { isActive } from './utils';
import { TrayMenu } from '@ux/tray-menu';
import Link from '@ux/link';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import classnames from 'classnames';

class Navigation extends Component {
  constructor(props) {
    super(...arguments);

    this.currentHref = props.currentHref;
    this.renderItems = this.renderItems.bind(this);
    this.closeNavTrays = this.closeNavTrays.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.trayMenus = Object.create(null);
  }

  /**
   * Close all navigation menus
   *
   * @memberof Navigation
   * @private
   */
  closeNavTrays() {
    for (const menu in this.trayMenus) { // eslint-disable-line guard-for-in
      this.trayMenus[menu].close();
    }
  }

  /**
   * Function to call when clicking an item.
   *
   * @param {Object} item The item containing a custom onClick function
   * @param {React.MouseEvent} event The click event
   * @memberof Navigation
   * @private
   */
  onItemClick(item, event) {
    if (item.onClick) {
      item.onClick(event);
    }
    this.closeNavTrays();
  }

  /**
   * Render a column of nav items. Used inside of a multi-column tray.
   * @param  {Object} item Navigation item whose children to render in a column
   * @param  {String} key The key for the heading list elements
   * @returns {React.ReactElement[]} Array of list elements containing a heading and links
   */
  renderColumn(item, key) {
    const { renderMobile } = this.props;
    // discard if it's not mobile and mobileOnly, or vice versa
    if (renderMobile ? item.desktopOnly : item.mobileOnly) {
      return null;
    }
    const columnItems = [];

    // push the heading for the column if there is one
    if (item.caption) {
      columnItems.push(
        <li key={ key } { ...item.dataAttrs }>
          { item.caption }
        </li>
      );
    }
    // then push the links
    for (var i = 0; i < item.children.length; i++) {
      const childItem = item.children[i];
      // discard if it's not mobile and mobileOnly, or vice versa
      if (renderMobile ? childItem.desktopOnly : childItem.mobileOnly) {
        continue;
      }

      const classNames = classnames(
        item.className,
        { active: isActive(childItem, this.currentHref, false) }
      );
      const { eid } = childItem;
      const props = {
        'className': classNames,
        'target': childItem.target,
        'id': childItem.id,
        'href': childItem.href || '#',
        'data-eid': eid,
        'onClick':
          childItem.onClick &&
          !eid &&
          (e => {
            this.onItemClick(childItem, e);
          }),
        'onClicked': this.closeNavTrays,
        ...childItem.dataAttrs
      };
      columnItems.push(
        <li
          key={ (childItem.caption || childItem.id) + i }
          className={ childItem.className }>
          <Link { ...props }>{ childItem.caption }</Link>
        </li>
      );
    }
    return columnItems;
  }

  /**
   * Render a single nav item. Used inside of a single column tray.
   * @param  {Object} item Navigation item to render
   * @param  {String} key The key for each list element
   * @param  {Boolean} [useLi=true] Whether or not to wrap the link in an <li>
   * @returns {React.ReactElement} List element containing a @ux/link
   */
  renderSingleItem(item, key, useLi = true) {
    const { renderMobile } = this.props;
    // discard if it's not mobile and mobileOnly, or vice versa
    if (renderMobile ? item.desktopOnly : item.mobileOnly) {
      return null;
    }
    const classNames = classnames(
      item.className,
      { active: isActive(item, this.currentHref, false) }
    );
    const { eid } = item;
    const props = {
      'className': classNames,
      'target': item.target,
      'id': item.id,
      'href': item.href || '#',
      'data-eid': item.eid,
      'onClick':
        item.onClick &&
        !eid &&
        (e => {
          this.onItemClick(item, e);
        }),
      'onClicked': this.closeNavTrays,
      ...item.dataAttrs
    };

    const link = (
      <Link key={ key } { ...props }>
        { item.caption}
      </Link>
    );

    if (useLi) {
      return (
        <li key={ key } className={ props.className }>
          { link }
        </li>
      );
    }

    return link;
  }

  /**
   * Create a list with all nested data structures
   * rendered appropriately.
   *
   * @param {Object} navItem the parent nav item of a collection of columns
   * @param {Boolean} [multi] True if the nav structure is multi-column
   * @returns {React.ReactElement} Columns
   * @private
   */
  renderColumns(navItem, multi) {
    if (multi) {
      return navItem.children.map((child, k) => {
        return (
          <ul
            key={ k }
            className='nav-column'
            { ...child.dataAttrs }>
            { this.renderColumn(child, k) }
          </ul>
        );
      });
    }

    return (
      <ul className='nav-column' { ...navItem.dataAttrs }>
        { navItem.children.map((child, k) => {
          return this.renderSingleItem(child, k);
        }) }
      </ul>
    );
  }

  /**
   * Determines if the root navigation structure is multi- or single columned
   *
   * @param {Object} navItem the root node of the nav structure
   * @returns {Boolean} true if multi column
   * @private
   */
  isMultiColumn(navItem) {
    return (
      navItem.children &&
      navItem.children.every(child => Array.isArray(child.children))
    );
  }

  /**
   * Render items that appear on the right side of the navigation
   * @param  {Object} item Object containing details of the navigation item
   * @param  {Number} i Index value
   * @returns {React.ReactElement} Either a button-link or a string of text
   * @private
   */
  renderNavRightItem(item, i) {
    if (item.href) {
      const design = item.design || 'default';
      const size = 'small';

      return (
        <li key={ i } className='nav-bottom-item'>
          <Button
            design={ design }
            size={ size }
            id={ item.id }
            href={ item.href }
            target={ item.target }
            onClick={ item.onClick }
            data-eid={ item.eid }
            { ...item.dataAttrs }>
            { item.caption }
          </Button>
        </li>
      );
    }
    return (
      <li key={ i } className='nav-bottom-item'>
        { item.caption }
      </li>
    );
  }

  /**
   * Render the navigation items
   *
   * @param {Object} item nav item
   * @param {Number} i index value
   * @returns {React.ReactElement} ReactElement
   * @private
   */
  renderItems(item, i) {
    const { renderMobile } = this.props;
    // discard if it's not mobile and mobileOnly, or vice versa
    if (renderMobile ? item.desktopOnly : item.mobileOnly) {
      return null;
    }
    const classNames = classnames(
      'nav-bottom-item',
      { active: isActive(item, this.currentHref) }
    );

    //
    // Render a tray-menu if the current item has children regardless of side
    //
    if (item.children && item.children.length) {
      const multiColumn = this.isMultiColumn(item);
      const columns = this.renderColumns(item, multiColumn);
      const trayTitle = <div className={ 'tray-title' }>{ item.caption }</div>;

      return (
        <li className={ classNames } key={ i }>
          <TrayMenu
            ref={ r => {
              this.trayMenus[i] = r;
            } }
            className={ classnames({ 'multi-col': multiColumn }) }
            showOpenCaret={ true }
            name={ trayTitle }
            data-eid={ item.eid }
            fullwidth={ false }
            showClose={ false }
            { ...item.dataAttrs }>
            { trayTitle }
            <div className='flex-columns'>{ columns }</div>
          </TrayMenu>
        </li>
      );
    }

    // if no children, handle rendering for right side
    if (this.props.side === 'right') {
      return this.renderNavRightItem(item, i);
    }

    const ariaCurrent = isActive(item, this.currentHref, false) ? 'page' : '';
    // handle render for left side
    return (
      <li key={ i } className={ classNames }>
        <a
          className='single-parent-link'
          id={ item.id }
          href={ item.href }
          target={ item.target }
          onClick={ item.onClick }
          data-eid={ item.eid }
          aria-current={ ariaCurrent }
          { ...item.dataAttrs }>
          { item.caption }
        </a>
      </li>
    );
  }

  /**
   * Render the navigation component
   *
   * @returns {React.ReactElement} ReactElement
   */
  render() {
    const props = this.props;
    const items = props.items;

    if (!items.length) {
      return null;
    }

    return (
      <ul className={ classnames('application-header-nav', `nav-${props.side}`) }>
        { items.map(this.renderItems) }
      </ul>
    );
  }
}

Navigation.defaultProps = {
  side: 'left',
  renderEmptyTitle: false,
  items: []
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */
Navigation.propTypes = {
  currentHref: PropTypes.string,
  items: PropTypes.array,
  side: PropTypes.string,
  renderMobile: PropTypes.bool,
  renderEmptyTitle: PropTypes.bool
};
export default Navigation;
