import golfToEid from 'golf-to-eid';

//
// Define the breakpoints the manifest need to listen to.
//
const breakpoints = [
  'mobile',
  'phablet',
  'tablet',
  'desktop',
  'large'
];

//
// Golf translation ids.
//
const golf = {
  'accountSettings': 'PC:AppHeader:Header:AccountSettings',
  'accountLabel': 'PC:AppHeader:Header:AccountLabel',
  'closeMenu': 'Shared:UtilityBar:Close',
  'products': 'PC:AppHeader:Header:MyProducts',
  'accountHome': 'PC:AppHeader:Header:MyAccountHome',
  'account': 'PC:AppHeader:Header:Account',
  'resources': 'PC:AppHeader:Header:Resources',
  'phoneNumbersHours': 'PC:AppHeader:Header:PhoneNumberHours',
  'shopGD': 'PC:AppHeader:Header:ShopGD',
  'renewals': 'PC:AppHeader:Header:MyRenewals',
  'pro': 'PC:AppHeader:Header:ProDashboard',
  'signin': 'Shared:UtilityBar:AccountTray:SignIn',
  'signout': 'Shared:AccountTray:SignOut',
  'policy': 'PC:AppHeader:Footer:PrivacyPolicy',
  'shopperid': 'PC:AppHeader:Header:CustomerNumber',
  'menu': 'PC:AppHeader:Header:ActiveSubMenuDefault',
  'pinLabel': 'Shared:AccountTray:CustomerPinLabel',
  'resetPin': 'Shared:UtilityBar:AccountTray:ResetPin',
  'viewPin': 'Shared:UtilityBar:AccountTray:ViewPin',
  'vip': 'PC:AppHeader:Header:VipRepName',
  'title': 'PC:AppHeader:Header:usermenu-title',
  'internalModeTitle': 'PC:AppHeader:Header:MyAccount',
  'inApp': 'Shared:Help:InAppTitle',
  'helpCenter': 'PC:AppHeader:Header:HelpCenter',
  'notifications': {
    closeText: 'Shared:UtilityBar:Close',
    headerText: 'Shared:UtilityBar:Notifications:HeaderText',
    failedNotificationsTitle: 'Shared:UtilityBar:Notifications:FailedNotificationsTitle',
    failedNotificationsText: 'Shared:UtilityBar:Notifications:FailedNotificationsText',
    failedNotificationsLink: 'Shared:UtilityBar:Notifications:FailedNotificationsLink',
    noNotificationsTitle: 'Shared:UtilityBar:Notifications:NoNotificationsTitle',
    noNotificationsText: 'Shared:UtilityBar:Notifications:NoNotificationsText',
    notificationsError: 'Shared:UtilityBar:Notifications:NotificationsError'
  },
  'username': 'Shared:UtilityBar:LoginForm:Username',
  'proRewardPoints': 'PC:AppHeader:Header:RewardPoints',
  'ssl': 'PC:AppHeader:Header:product-ssl',
  'domains': 'PC:AppHeader:Header:product-domains',
  'hosting': 'PC:AppHeader:Header:product-hosting',
  'email': 'PC:AppHeader:Header:product-email',
  'websites': 'PC:AppHeader:Header:product-websites',
  'email-office': 'PC:AppHeader:Header:product-email-office',
  'hosting-wordpress': 'PC:AppHeader:Header:product-hosting-wordpress',
  'home': 'PC:AppHeader:Header:Home',
  'manage-my-products': 'PC:AppHeader:Header:ManageMyProducts',
  'myAccount': 'PC:AppHeader:Header:MyAccount',
  'mainMenu': 'Shared:MobileNav:MainMenu',
  'quickLinks': 'Shared:UtilityBar:QuickLinks:Heading',
  'proHub': 'PC:AppHeader:Header:ProHub',
  'commerce': 'PC:AppHeader:Waffle:Commerce',
  'commercePayments': 'PC:AppHeader:Waffle:CommercePayments'
};

//
// Generate Traffic2 eid's to be used to track clicks. Map the generated
// strings back to the same keys the Golf translations strings have.
//
const eids = Object.keys(golf).reduce((acc, key) => {
  acc[key] = golfToEid(golf[key], {
    prefix: 'uxp.hyd.app_header',
    postfix: 'link.click'
  });
  return acc;
}, {});

export {
  breakpoints,
  golf,
  eids
};
