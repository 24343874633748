import NavigationBottom from './nav-bottom';
import NavigationTop from './nav-top';
import React, { Component } from 'react';
import { isBrowser, isMobile } from '@ux/component-utilities';
import PropTypes from 'prop-types';
import { URL } from '@ux/util';
import memoize from 'memoize-one';
import classnames from 'classnames';
import { withBreakpoints } from './utils';

const isDataAttr = /^data-[a-zA-Z0-9-]+$/;

export class Header extends Component {
  constructor() {
    super(...arguments);
    this.currentHref = this.getCurrentHref();
    this.setDataAttrs = this.setDataAttrs.bind(this);
    this.setDataAttrsOnNav = memoize(navigation => {
      navigation.map(this.setDataAttrs);
    });
  }

  /**
   * Sets a dataAttrs field containing all the `data-` attributes on a given item.
   * @param  {object} item A navigation item
   * @returns {Object} The updated navigation item with a `dataAttrs` field
   * @private
   */
  setDataAttrs(item) {
    if (!item) return;

    item.dataAttrs = Object.keys(item).reduce(function (acc, k) {
      if (isDataAttr.test(k)) {
        acc[k] = item[k];
      }
      return acc;
    }, {});

    if (item.children) {
      item.children.forEach(this.setDataAttrs);
    }

    return item;
  }

  /**
   * Get the current page href for comparison of active item
   *
   * @returns {String} current href
   * @private
   */
  getCurrentHref() {
    //
    // Logic originally from
    // https://github.secureserver.net/UX/product-nav/blob/***REMOVED***/lib/product-nav.js#L37
    //
    let { currentHref } = this.props;
    if (isBrowser()) {
      currentHref = document.location.href;
    }

    if (currentHref) {
      const currentUrl = new URL(currentHref, true);
      const qsCurrentHref = currentUrl.query.currentPage;
      if (qsCurrentHref) {
        currentHref = qsCurrentHref;
      } else {
        // Remove query string for comparisons
        currentUrl.set('query', {});
        currentHref = currentUrl.href;
      }
    }

    return currentHref;
  }

  render() {
    const props = this.props;
    const { loggedIn, customWaffleLinks } = props;
    const isDesktop = !~['mobile', 'phablet', 'tablet'].indexOf(props.breakpoint);
    const isReseller = props.privateLabelId !== 1;
    const showCustomWaffleMenu = customWaffleLinks && (customWaffleLinks.topLinks.length || customWaffleLinks.quickLinks.length);

    let showWaffleMenu = false;
    if (props.preset === 'application-header' && !isReseller) {
      if (['help', 'community', 'system-status'].indexOf(props.app) === -1) {
        // the help app is the only app with a logged-out state for app-header
        // if the app is not help, we dont have to check for loggedin and
        // can render server side to avoid flashing
        showWaffleMenu = true;
      } else if (loggedIn) {
        showWaffleMenu = true;
      }
    } else if (showCustomWaffleMenu) {
      showWaffleMenu = true;
    }

    const classNames = classnames('manifest', { pl: isReseller });

    this.setDataAttrsOnNav(props.navigation);

    return (
      <header className={ classNames }>
        <NavigationTop
          { ...props }
          CartComponent={ props.CartComponent }
          showWaffleMenu={ showWaffleMenu }
          customWaffleLinks={ showCustomWaffleMenu ? customWaffleLinks : null }
          isMobile={ isMobile(props.breakpoint) }
          isTablet={ props.breakpoint === 'tablet' }
          currentHref={ this.currentHref }
        />
        { isDesktop && (
          <NavigationBottom
            { ...props }
            currentHref={ this.currentHref }
          />
        )}
      </header>
    );
  }
}

Header.propTypes = {
  app: PropTypes.string.isRequired,
  breakpoint: PropTypes.string,
  currentHref: PropTypes.string,
  loggedIn: PropTypes.bool,
  navigation: PropTypes.array.isRequired,
  CartComponent: PropTypes.elementType,
  features: PropTypes.object,
  preset: PropTypes.string,
  privateLabelId: PropTypes.number,
  showWaffleMenu: PropTypes.bool,
  customWaffleLinks: PropTypes.object
};

export default withBreakpoints(Header);
