/* eslint-disable no-process-env */
import HivemindClient, { hivemindConstants } from '@godaddy/hivemind-sdk-browser';
import { cookie } from '@ux/util';
import { getLocation } from './Location';

const hosts = [
  'dev-godaddy.com',
  'dev-secureserver.net',
  'dev-123-reg.co.uk',
  'test-godaddy.com',
  'test-secureserver.net',
  'test-123-reg.co.uk',
  'ote-godaddy.com',
  'ote-secureserver.net',
  'godaddy.com',
  'secureserver.net',
  '123-reg.co.uk'
];

const defaultHost = 'godaddy.com';

/**
 * Get the current environment from the site url
 * @returns {string} - the environment of the current site
 */
export function getEnv() {
  const host = getHost() || '';

  if (host.includes('dev-')) {
    return 'dev';
  } else if (host.includes('test-')) {
    return 'test';
  } else if (host.includes('ote-')) {
    return 'ote';
  }

  return 'prod';
}

/**
 * Get the url for the cart checkout api
 * @returns {string} - base cart checkout api url for the current site
 */
export function getBaseApiUrl() {
  if (typeof process !== 'undefined' && process?.env?.STORYBOOK_NOTIFICATIONS_API) {
    return process.env.STORYBOOK_NOTIFICATIONS_API;
  }

  return `https://notifications-api.${getHost()}`;
}

/**
 * Get the hostname for the current site
 * @returns {string} - the hostname of the current site
 */
export function getHost() {
  const { hostname } = getLocation() || {};
  if (!hostname) return defaultHost;
  return hosts.find(host => hostname.includes(host));
}

/**
 * Get the search params for the current url
 * @returns {object} - the search params for the current url
 */
export function getUrlParams() {
  const { search } = getLocation();
  const searchParams = new URLSearchParams(search);
  return Object.fromEntries(searchParams.entries());
}

let client;

function getShopperFromCookie() {
  const infoIdp = cookie.get('info_idp');

  if (!infoIdp) {
    return null;
  }
  const json = JSON.parse(decodeURIComponent(infoIdp));

  if (json.auth !== 'basic') {
    return json[json.auth].info_shopperId;
  }

  return json.info_shopperId;
}

export function getCohortIdFromCookie(experimentId) {
  const pxCohorts = cookie.get('px_cohorts');
  if (!pxCohorts) {
    return null;
  }

  const cohorts = JSON.parse(decodeURIComponent(pxCohorts));

  return cohorts?.[experimentId]?.cohortId;
}

export async function getExperiments(experiments) {
  const shopperId = getShopperFromCookie();
  if (!client) {
    client = new HivemindClient({
      app: 'notifications',
      env: getEnv(),
      subject: {
        [hivemindConstants.idTypes.SHOPPER_ID]: shopperId
      },
      usePreload: false
    });

    await client.ready;

    return await client.getCohorts({ experiments });
  }
}

export function getUxHeader() {
  let result = null;
  const { header = null } = window.ux?.eldorado || {};
  if (header && typeof header === 'function') {
    header((err, uxHeader) => {
      if (!err) result = uxHeader;
    });
  }
  return result;
}

export const expirations = {
  oneHour: 60 * 60 * 1000,
  oneDay: 24 * 60 * 60 * 1000
};

export const getCookieExpiration = (expiration = expirations.oneHour) => {
  return new Date(Date.now() + expiration).toGMTString();
};

export function getSsoUrl() {
  const { host, pathname, search } = window.location;
  const path = encodeURIComponent(`${pathname}${search}`);
  const app = host.split('.')[0] || 'www';
  return `https://sso.${getHost()}/?realm=idp&path=${path}&app=${app}&auth_reason=1`;
}
