const isRelativeURL = /^\/(?:[^/]|$)/;
const absoluteURLPrefix = /^https?:\/\/[^/]*/;
import { breakpoints } from './enums';
import { useBreakpoints } from '@ux/component-utilities';
import addhoc from 'addhoc';

/**
 * Determine if an item is active, or if any of it's children are active.
 *
 * @param {Object} item nav item
 * @param {String} currentHref the current url in the browser
 * @param {Boolean} [recurse=true] flag to enable recursion of children
 * @returns {Boolean} whether item is active
 * @private
 */
export function isActive(item, currentHref, recurse) {
  if (typeof (item.active) === 'boolean') return item.active;

  if (item.href && item.href === currentHref) return true;

  // Handle relative URLs
  if (currentHref && isRelativeURL.test(item.href) && item.href === currentHref.replace(absoluteURLPrefix, '')) {
    return true;
  }

  if (recurse !== false && item.children) {
    return item.children.some(child => isActive(child, currentHref));
  }

  return false;
}

export const withBreakpoints = addhoc(getWrappedComponent => {
  const { breakpoint } = useBreakpoints(...breakpoints);
  return getWrappedComponent({ breakpoint });
}, 'WithBreakpoints');
