/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { golf, eids } from './enums';
import { url } from '@ux/header-util';
import { TrayMenu } from '@ux/tray-menu';
import { FormattedMessage } from '@godaddy/react-mintl';
import Link from '@ux/link';
import golfToEid from 'golf-to-eid';
import { default as UserIcon } from '@ux/icon/user';
import classnames from 'classnames';
import { withBreakpoints } from './utils';

/**
 * Render User details when logged in.
 *
 * @Constructor
 * @class UserDetails
 * @api public
 */
class UserDetails extends Component {
  renderRewardPoints() {
    const props = this.props;

    if (typeof props.rewardPoints !== 'number') {
      return null;
    }

    return (
      <div id='user-details-reward-points' className='reward-points'>
        <FormattedMessage id={ golf.proRewardPoints } />: { props.rewardPoints }
      </div>
    );
  }

  render() {
    const props = this.props;

    if (!props.loggedIn) {
      return null;
    }

    let details = null;

    if (props.preset === 'internal-header') {
      details = (
        <div className='account-name'>
          <FormattedMessage id={ golf.username } />: { props.username }
        </div>
      );
    } else if (props.privateLabelType !== 3) {
      details = (
        <div>
          <div className='shopper-id'>
            <FormattedMessage id={ golf.shopperid } />: { props.shopperId }
          </div>
          { props.privateLabelType !== 14 && <div className='shopper-pin'>
            <FormattedMessage id={ golf.pinLabel } />{' '}
            <a { ...props.urls.viewPin } target='_blank'>
              <FormattedMessage id={ golf.viewPin } />
            </a>
          </div>
          }
        </div>
      );
    }

    return (
      <div>
        { details }
        { props.proshopper && !props.pcx && this.renderRewardPoints() }
        { props.privateLabelId === 1 && <VIP details={ props.vip } /> }
      </div>
    );
  }
}

/**
 * Property definitions.
 *
 * @type {Object}
 * @api public
 */
UserDetails.propTypes = {
  loggedIn: PropTypes.bool,
  urls: PropTypes.object.isRequired,
  name: PropTypes.string,
  shopperId: PropTypes.string,
  vip: PropTypes.object,
  username: PropTypes.string,
  pcx: PropTypes.bool,
  preset: PropTypes.string,
  privateLabelId: PropTypes.number,
  privateLabelType: PropTypes.number,
  proshopper: PropTypes.bool,
  proRewardPoints: PropTypes.number,
  rewardPoints: PropTypes.number
};

/**
 * Render VIP information if required.
 *
 * @Constructor
 * @class VIP
 * @api public
 */
class VIP extends Component {
  render() {
    const props = this.props;
    const vip = props.details;

    if (typeof vip !== 'object') {
      return null;
    }

    return (
      <div className='shopper-vip'>
        <div className='shopper-vip-label'>
          <FormattedMessage id={ golf.vip } />:
        </div>
        <div className='shopper-vip-details'>
          <div>{ vip.repname }</div>
          <div>{ vip.phone }</div>
          <a href={ `mailto:${vip.email}` }>{ vip.email }</a>
        </div>
      </div>
    );
  }
}

/**
 * Property definitions.
 *
 * @type {Object}
 * @api private
 */
VIP.propTypes = {
  details: PropTypes.object
};

/**
 * Render user dropdown menu.
 *
 * @Constructor
 * @class User
 * @api public
 */
class User extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      open: false
    };
  }

  /**
   * Show link for pro dashboard only added if a user is pro
   *
   * @returns {React.Component} Component
   * @api private
   */
  renderPro() {
    const props = this.props;

    if (props.loggedIn && props.proshopper && !props.pcx) {
      return (
        <a
          className='customer-link'
          { ...props.urls.proHomeApp }
          data-eid={ eids.pro }>
          <FormattedMessage tagName='span' id={ golf.pro } />
        </a>
      );
    }
    return null;
  }

  /**
   * Update AccountTrayNav with what is provided via the updateAccountTrayNav extensibility endpoint.
   *
   * @returns {React.Component} Component
   */

  renderCustomAccountTrayNav() {

    const customLinks = [];
    // If customAccountTrayNav is false  and sent in with replaceAccountTrayNavLinks = true we will return here
    // this effectively gets rid of the hardcoded accountTrayNav links.
    if (!this.props.customAccountTrayNav) return;

    this.props.customAccountTrayNav.forEach((link) => {
      customLinks.push(
        (<a
          className='customer-link'
          key={ link.caption }
          href={ link.href }
          data-eid={ link.eid || `uxp.applicationHeader.accountTrayNav.${link.caption}.customLink` }
          caption={ link.caption } >
          { link.caption }
        </a>)
      );
    });

    return customLinks;
  }

  /**
   * Show title on the dropdown menu, or show the user's name
   * if user has a name
   *
   * @returns {React.Component} Component
   */
  renderDropdownHeader() {
    const props = this.props;

    if (props.loggedIn && props.name) {
      return props.name;
    }
    return (
      <FormattedMessage
        id={
          props.preset === 'internal-header'
            ? golf.internalModeTitle
            : golf.title
        }
      />
    );
  }

  /**
   * Show link for products, account, and renewals except
   * for internal header and pl
   *
   * @returns {React.Component} Component
   * @api private
   */
  renderLinks() {
    const props = this.props;
    if (props.preset === 'internal-header') return null;

    const links = [
      (props.privateLabelType !== 3 ||
        (props.resellerUrls && props.resellerUrls.products)) && (
        <a
          className='customer-link'
          key='products'
          href={ url.updateQuery(props.urls.products.href, props.urlArgs) }
          data-eid={ eids.products }>
          <FormattedMessage id={ golf.products } />
        </a>
      ),
      (props.privateLabelType !== 3 ||
        (props.resellerUrls && props.resellerUrls.account)) && (
        <a
          className='customer-link'
          key='account'
          { ...props.urls.account }
          data-eid={ eids.accountSettings }>
          <FormattedMessage id={ golf.accountSettings } />
        </a>
      ),
      props.privateLabelType !== 3 && (
        <a
          className='customer-link'
          key='renewals'
          { ...props.urls.renewals }
          data-eid={ eids.renewals }>
          <FormattedMessage id={ golf.renewals } />
        </a>
      )
    ].filter(Boolean);

    if (this.props.customAccountTrayNav) {
      links.push(this.renderCustomAccountTrayNav());
    }

    if (props.privateLabelId === 1) {
      links.push(
        ...[
          <div className='tray-menu-divider' key='resourcesDivider' />,
          <a
            className='customer-link'
            key='shopGD'
            { ...props.urls.home }
            data-eid={ eids.shopGD }>
            <FormattedMessage id={ golf.shopGD } />
          </a>
        ]
      );
    }

    if (props.features.accountTrayContactLink) {
      // Check if the private label ID is 123Reg
      if (props.privateLabelId === 587240) {
        // Determine the domain environment prefix based on the environment
        const domainEnvPrefix = (props.env !== 'prod') ? `${props.env}-` : '';
        // Construct the contact URL using the determined prefix
        const regContactUrl = `https://www.${domainEnvPrefix}123-reg.co.uk/contact/`;

        // Push the divider and contact link elements to the links array
        links.push(
          <div className='tray-menu-divider' key='resourcesDivider' />,
          <a
            className='customer-link'
            key='phoneNumbersHours'
            href={ regContactUrl }
            data-eid={ eids.phoneNumbersHours }>
            <FormattedMessage id={ golf.phoneNumbersHours } />
          </a>
        );
      } else {
        // For other cases, push the default contact link
        links.push(
          <a
            className='customer-link'
            key='phoneNumbersHours'
            { ...props.urls.contactUs }
            data-eid={ eids.phoneNumbersHours }>
            <FormattedMessage id={ golf.phoneNumbersHours } />
          </a>
        );
      }
    }

    return links;
  }

  renderTrayContent() {
    const { props } = this;
    const status = props.loggedIn ? 'signout' : 'signin';
    if (props.quickLinks) {
      return (
        <React.Fragment>
          <div className='quick-links-heading'>
            <FormattedMessage id={ golf.quickLinks } />
          </div>
          <Link
            className='quick-link'
            href={ props.urls.products.href }
            data-eid={ golfToEid(golf.products, {
              prefix: 'uxp.hyd.app_header_logo_tray',
              postfix: 'link.click'
            }) }
            urlArgs={ props.urlArgs }>
            <FormattedMessage id={ golf.products } />
          </Link>
          <Link
            className='quick-link'
            href={ props.urls.home.href }
            data-eid={ golfToEid(golf.quickLinks, {
              prefix: 'uxp.hyd.app_header_logo_tray',
              postfix: 'link.click'
            }) }
            urlArgs={ props.urlArgs }>
            <FormattedMessage id={ golf.shopGD } />
          </Link>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className='customer-info-wrap'>
          <div className='customer-name'>{ this.renderDropdownHeader() }</div>
          <div className='customer-details'>
            <UserDetails { ...props } />
          </div>
        </div>
        <div key='accountText' className='tray-heading'>
          <FormattedMessage id={ golf.account } />
        </div>
        { this.renderPro() }
        { this.props.replaceAccountTrayNavLinks ?  this.renderCustomAccountTrayNav() : this.renderLinks() }
        <a
          className='customer-link login'
          href={ url.updateQuery(
            props.urls.sso[status === 'signin' ? 'login' : 'logout'].href,
            props.sso
          ) }
          data-eid={ eids[status] }>
          <FormattedMessage id={ golf[status] } />
        </a>
      </React.Fragment>
    );
  }

  render() {
    const props = this.props;
    const { privateLabelId } = props;
    const isDesktop = !~['mobile', 'phablet', 'tablet'].indexOf(props.breakpoint);

    let trayName = props.trayName;

    if (!trayName) {
      if (isDesktop && privateLabelId === 1) {
        if (props.loggedIn) {
          // props.displayName is the short name (e.g. "Jane").
          trayName = props.displayName;
        } else {
          trayName = <FormattedMessage id={ golf.signin } />;
        }
      } else {
        trayName = <UserIcon width={ 29 } height={ 29 } />;
      }
    }

    const traySpanClassName =
      privateLabelId !== 1 || !isDesktop
        ? 'app-icon account-tray-icon'
        : 'customer-name-span';

    return (
      <div>
        {props.preset === 'internal-header' ? (
          <FormattedMessage id={ golf.internalModeTitle }>
            { internalModeTitle => (
              <TrayMenu
                ariaLabel={ internalModeTitle }
                trayAlignment='right'
                name={ trayName }
                className={ classnames(
                  'customer-menu',
                  props.quickLinks && 'logo-tray'
                ) }
                showOpenCaret={ privateLabelId === 1 }
                traySpanClass={ props.traySpanClassName || traySpanClassName }
                type='base'
                fullwidth={ false }
                onToggle={ () => {
                  this.setState({
                    open: !this.state.open
                  });
                } }>
                { this.renderTrayContent() }
              </TrayMenu>
            )}
          </FormattedMessage>
        ) : (
          <FormattedMessage id={ golf.title }>
            { title => (
              <TrayMenu
                ariaLabel={ title }
                trayAlignment='right'
                name={ trayName }
                className={ classnames(
                  'customer-menu',
                  props.quickLinks && 'logo-tray'
                ) }
                showOpenCaret={ privateLabelId === 1 }
                traySpanClass={ props.traySpanClassName || traySpanClassName }
                type='base'
                fullwidth={ false }
                onToggle={ () => {
                  this.setState({
                    open: !this.state.open
                  });
                } }>
                { this.renderTrayContent() }
              </TrayMenu>
            )}
          </FormattedMessage>
        )}
        { (props.isMobile || props.isTablet) && this.state.open && (
          <div className='menu-bg' />
        ) }
      </div>
    );
  }
}

/**
 * Property definitions.
 *
 * @type {Object}
 * @api public
 */
User.propTypes = {
  customAccountTrayNav: PropTypes.array,
  replaceAccountTrayNavLinks: PropTypes.bool,
  breakpoint: PropTypes.string,
  env: PropTypes.string,
  urls: PropTypes.object.isRequired,
  urlArgs: PropTypes.object,
  privateLabelId: PropTypes.number,
  privateLabelType: PropTypes.number,
  displayName: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  intl: PropTypes.object,
  features: PropTypes.shape({
    accountTrayContactLink: PropTypes.bool
  }),
  loggedIn: PropTypes.bool,
  pcx: PropTypes.bool,
  proshopper: PropTypes.bool,
  sso: PropTypes.object,
  version: PropTypes.string,
  name: PropTypes.string,
  preset: PropTypes.string,
  resellerUrls: PropTypes.shape({
    products: PropTypes.string,
    account: PropTypes.string
  }),
  quickLinks: PropTypes.bool,
  trayName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  traySpanClassName: PropTypes.string
};

const UserWrapper = withBreakpoints(User);

export {
  UserDetails,
  VIP,
  UserWrapper as User,
  User as UserComponent
};
