import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { golf } from './enums';
import { FormattedMessage } from '@godaddy/react-mintl';
import PopOver from '@ux/popover';
import Link from '@ux/link';
import QuickLinks from './quick-links';
import TopLinks from './top-links';
/**
 * Render A waffle menu to show a long list of products
 *
 * @param {Object} options Properties to spread on the item
 * @param {Function} [namespace=no-op] Function to namespace `className`
 * @returns {React.Element} A Popover Menu that uses a waffle control.
 * @api private
 */
class WaffleMenu extends Component {
  /**
   * Generate a link
   * @param  {Object} opts enumeration of link content
   * @param  {String} opts.message title of link
   * @param  {String} opts.id golf id
   * @param  {String} opts.href link to product
   * @param  {String} opts.eid eid to track click
   * @param  {String} opts.caption pretranslated string or FormattedMessage
   * @returns {React.element} link to product
   */
  link({ message, id, href, eid, urlArgs, caption }) {
    return (
      <Link
        id={ id }
        urlArgs={ urlArgs }
        title={ message }
        href={ href }
        data-eid={ eid }
        className='waffle-link'
        key={ `waffle-menu-${id || caption}` }>
        { caption }
      </Link>
    );
  }

  links() {
    const { urls, urlArgs, messages, customWaffleLinks, showWaffleCommerceLink = false } = this.props;

    const eidPrefix = 'uxp.hyd.waffle_menu';

    const commerceLink = {
      eid: `${eidPrefix}.product_commerce.link.click`,
      href: urls.manageCommerce.href,
      id: golf.commercePayments,
      caption: <FormattedMessage id={ golf.commercePayments } />,
      message: messages[golf.commerce],
      urlArgs
    };

    if (customWaffleLinks) {
      const customTopLinks = customWaffleLinks.topLinks;
      const customQuickLinks = customWaffleLinks.quickLinks;

      return {
        topLinks: customTopLinks.map((topLink) => this.link(topLink)),
        quickLinks: customQuickLinks.map((quickLink) => this.link(quickLink))
      };
    }

    const originalQuickLinks = [
      {
        eid: `${eidPrefix}.product_domains.link.click`,
        href: urls.manageDomains.href,
        id: golf.domains,
        caption: <FormattedMessage id={ golf.domains } />,
        message: messages[golf.domains],
        urlArgs
      },
      {
        eid: `${eidPrefix}.product_websites.link.click`,
        href: urls.manageWebsites.href,
        id: golf.websites,
        caption: <FormattedMessage id={ golf.websites } />,
        message: messages[golf.websites],
        urlArgs
      },
      {
        eid: `${eidPrefix}.product_hosting_wordpress.link.click`,
        href: urls.manageHosting.href,
        id: golf['hosting-wordpress'],
        caption: <FormattedMessage id={ golf['hosting-wordpress'] } />,
        message: messages[golf['hosting-wordpress']],
        urlArgs
      },
      {
        eid: `${eidPrefix}.product_email_office.link.click`,
        href: urls.bigthree.email.href,
        id: golf['email-office'],
        caption: <FormattedMessage id={ golf['email-office'] } />,
        message: messages[golf['email-office']],
        urlArgs
      },
      {
        eid: `${eidPrefix}.product_ssl.link.click`,
        href: urls.bigthree.ssl.href,
        id: golf.ssl,
        caption: <FormattedMessage id={ golf.ssl } />,
        message: messages[golf.ssl],
        urlArgs
      }];

    if (showWaffleCommerceLink) originalQuickLinks.push(commerceLink);

    const quickLinks = originalQuickLinks.map(this.link);

    return {
      topLinks: [
        this.link({
          eid: `${eidPrefix}.manage_my_products.link.click`,
          href: urls.products.href,
          id: golf['manage-my-products'],
          caption: <FormattedMessage id={ golf['manage-my-products'] } />,
          message: messages[golf['manage-my-products']],
          urlArgs
        }),
        this.link({
          eid: `${eidPrefix}.shop_gd.link.click`,
          href: urls.home.href,
          id: golf.shopGD,
          caption: <FormattedMessage id={ golf.shopGD } />,
          message: messages[golf.shopGD],
          urlArgs
        })],
      quickLinks
    };
  }

  render() {
    const { showWaffleMenu, control } = this.props;

    if (!showWaffleMenu) {
      return null;
    }

    const links = this.links();
    const hasTopAndQuickLinks = !!(links.topLinks.length && links.quickLinks.length);

    return (
      <PopOver
        control={ control }
        eid='uxp.hyd.app_header.waffle_menu.click'
        className='waffle-menu'>
        <Fragment>
          <TopLinks links={ links.topLinks } />
          { hasTopAndQuickLinks && <div className='tray-menu-divider'></div> }
          { !!(links.quickLinks.length) && <QuickLinks links={ links.quickLinks } /> }
        </Fragment>
      </PopOver>
    );
  }
}

WaffleMenu.propTypes = {
  control: PropTypes.element,
  showWaffleMenu: PropTypes.bool,
  urls: PropTypes.object.isRequired,
  urlArgs: PropTypes.object,
  messages: PropTypes.object,
  customWaffleLinks: PropTypes.object,
  showWaffleCommerceLink: PropTypes.bool
};

export default WaffleMenu;
