import React from 'react';
import PropTypes from 'prop-types';

export default function TopLinks(props) {
  return (
    <div className='top-links'>{ props.links }</div>
  );
}

TopLinks.propTypes = {
  links: PropTypes.array
};
