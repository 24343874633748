import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Navigation from './header-navigation';
import memoize from 'memoize-one';

class NavigationSet extends Component {
  constructor() {
    super(...arguments);

    this.emptyNav = memoize(navigation => { return navigation.every(navItem => !navItem.caption); });
    this.emptyNavRight = memoize(navigation => { return navigation.every(navItem => !navItem.caption); });
  }

  render() {
    const props = this.props;
    const {
      navigation,
      navigationRight,
      navigationPosition,
      currentHref
    } = props;

    //
    // Remark: Do NOT render the navigation server side it will cause mobile clients
    // to briefly show/flash a large or desktop rendered navigation view.
    //
    if (
      typeof window === 'undefined' ||
      (this.emptyNav(navigation) && this.emptyNavRight(navigationRight))
    ) {
      return null;
    }

    return (
      <div className={ `${navigationPosition}-nav-set` }>
        <Navigation
          items={ navigation }
          currentHref={ currentHref }
        />
        <Navigation
          items={ navigationRight }
          currentHref={ currentHref }
          side='right'
        />
      </div>
    );
  }
}

/**
 * Default properties for navigation bar.
 *
 * @type {Object}
 * @api public
 */
NavigationSet.defaultProps = {
  navigation: [],
  navigationRight: [],
  navigationPosition: 'bot'
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */
NavigationSet.propTypes = {
  currentHref: PropTypes.string,
  navigation: PropTypes.array.isRequired,
  navigationRight: PropTypes.array,
  navigationPosition: PropTypes.oneOf(['bot', 'top', 'right'])
};

export default NavigationSet;
