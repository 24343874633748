import { FormattedMessage } from '@godaddy/react-mintl';
import React from 'react';
import PropTypes from 'prop-types';
import { golf, eids } from './enums';
import Link from '@ux/link';
import ChevronLeft from '@ux/icon/chevron-left';
import { cookie } from '@ux/util';

export default function HubBanner(props) {
  let pcx = false;
  try {
    const infoIDP = decodeURIComponent(cookie.get('info_idp'));
    pcx = JSON.parse(infoIDP).pcx;
  } catch (err) {
    return null;
  }
  if (!pcx) { return null; }

  return (
    <div className='hub-banner'>
      <Link
        data-eid={ eids.proHub }
        href={ props.href }>
        <ChevronLeft />
        <FormattedMessage id={ golf.proHub } />
      </Link>
    </div>
  );
}

HubBanner.propTypes = {
  href: PropTypes.string.isRequired
};
