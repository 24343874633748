/* eslint-disable max-statements */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getInfo } from './getInfo';

function Loader(props) {
  const { id, onPrefetch, loadingSpinner } = props;

  useEffect(() => {
    onPrefetch?.();
    getInfo(props);
  }, []);

  return (
    <div id={ id }>
      {loadingSpinner}
    </div>
  );
}

export default Loader;

Loader.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  entryKey: PropTypes.string.isRequired,
  nameSpace: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  replaceSrcMethodName: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onPrefetch: PropTypes.func,
  onAssetsLoad: PropTypes.func,
  loadingSpinner: PropTypes.node
};
